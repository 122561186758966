import React, { useState } from "react";
// import { Link } from "gatsby";
import styled from "styled-components";
import { CopyBlock, dracula } from "react-code-blocks";
import Lottie from "react-lottie";

import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";
import StyledCodeBlockWrapper from "../../shared/StyledCodeBlockWrapper";
import { StyledSubTitle1, StyledDesc1, StyledFooterTitle } from "../../shared/StyledTypography";
import StyledButton from "../../shared/StyledButton";
import StyledToggleWrapper from "../../shared/StyledToggleWrapper";
import { COLORS } from "../../../globals/designSystem";
import {
  removePaddBottom,
  showTab,
  hideTab,
  alignSelfStart,
  alignSelfEnd,
  textUppercase,
} from "../../shared/CommonStyled";
import { mq } from "../../../globals/utils";

import hasLoader from "../images/has_loadin.json";
import tickCircle from "../../homepagenew/images/tick-circle.svg";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: hasLoader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const depthLimitingQuery = `query {
  films(limit: 5) {
    id
    title
    rating
    categories {
      category_id
      category {
        id
        name
      }
    }
  }
}`;

const defaultResponseDepthLimiting = `{
  "errors": [
    {
      "extensions": {
        "path": "$",
        "code": "node-and-depth-limit-exceeded"
      },
      "message": "operation node and depth limit exceeded"
    }
  ]
}
`;

const nodeLimitingQuery = `query {
  films(limit: 5) {
    id
    title
    rating
    release_year
    categories {
      category_id
    }
    actors {
      actor_id
    }
  }
}`;
const defaultResponseNodeLimiting = `{
  "errors": [
    {
      "extensions": {
        "path": "$",
        "code": "node-limit-exceeded"
      },
      "message": "operation node limit exceeded"
    }
  ]
}`;

const rateLimitingQuery = `query {
  films(limit: 5) {
    id
    title
    rating
    release_year
  }
}`;

const defaultResponseRateLimiting = `{
  "data": {
    "films": [
      {
        "id": 1,
        "title": "Academy Dinosaur",
        "rating": "PG",
        "release_year": 2006
      },
      {
        "id": 2,
        "title": "Ace Goldfinger",
        "rating": "G",
        "release_year": 2006
      },
      {
        "id": 3,
        "title": "Adaptation Holes",
        "rating": "NC-17",
        "release_year": 2006
      },
      {
        "id": 4,
        "title": "Affair Prejudice",
        "rating": "G",
        "release_year": 2006
      },
      {
        "id": 5,
        "title": "African Egg",
        "rating": "G",
        "release_year": 2006
      }
    ]
  }
}`;

const queryDemoWithCaching = `query {
  films(limit: 5) {
    id
    title
    rating
    release_year
  }
}`;

const StyledCodeBlockWrapperTwoColumn = styled(StyledCodeBlockWrapper)`
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }

  code {
    flex: 1;
  }
`;

const StyledPreventAbuseWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 120px;
  .pr-100 {
    padding-right: 100px;
  }
  ${mq.between("md", "lg")} {
    grid-gap: 32px;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
`;
const StyledPreventAbuseImg = styled.div`
  padding-bottom: 100px;
  border-bottom: 1px solid ${COLORS.grey_30};
  ${mq.below.md} {
    padding-bottom: 50px;
  }
`;
const PreventAbuse = () => {
  const [currentTab, setCurrentTab] = useState("depth-limiting");
  const [rateLimiting, setRateLimiting] = useState({ data: null });
  const [depthLimiting, setDepthLimiting] = useState({ data: null });
  const [nodeLimiting, setNodeLimiting] = useState({ data: null });
  const [rateLimitingLoading, setRateLimitingLoading] = useState(false);
  const [depthLimitingLoading, setDepthLimitingLoading] = useState(false);
  const [nodeLimitingLoading, setNodeLimitingLoading] = useState(false);

  const fetchQuery = async (queryToFetchData, securityType) => {
    try {
      const response = await fetch("https://advanced-performance.hasura.app/v1/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          query: queryToFetchData,
        }),
      });
      const responseJson = await response.json();
      const stringifiedData = JSON.stringify(responseJson, null, 2);

      if (securityType === "rate-limiting") {
        setRateLimiting(prevState => ({ ...prevState, data: stringifiedData }));
      } else if (securityType === "depth-limiting") {
        setDepthLimiting(prevState => ({ ...prevState, data: stringifiedData }));
      } else if (securityType === "node-limiting") {
        setNodeLimiting(prevState => ({ ...prevState, data: stringifiedData }));
      }
    } catch (error) {
      if (securityType === "rate-limiting") {
        setRateLimiting(prevState => ({
          ...prevState,
          data: error.message || "Somethiing Went Wrong!",
        }));
      } else if (securityType === "depth-limiting") {
        setDepthLimiting(prevState => ({
          ...prevState,
          data: error.message || "Somethiing Went Wrong!",
        }));
      } else if (securityType === "node-limiting") {
        setNodeLimiting(prevState => ({
          ...prevState,
          data: error.message || "Somethiing Went Wrong!",
        }));
      }
    }
  };

  // Time Measurement Ref: https://developer.mozilla.org/en-US/docs/Web/API/Performance/now
  const getMeasuredQueryResponseDepthLimiting = async () => {
    setDepthLimitingLoading(true);
    await fetchQuery(depthLimitingQuery, "depth-limiting");
    setDepthLimitingLoading(false);
  };

  const getMeasuredQueryResponseRateLimiting = async () => {
    setRateLimitingLoading(true);
    await fetchQuery(rateLimitingQuery, "rate-limiting");
    setRateLimitingLoading(false);
  };

  const getMeasuredQueryResponseNodeLimiting = async () => {
    setNodeLimitingLoading(true);
    await fetchQuery(nodeLimitingQuery, "node-limiting");
    setNodeLimitingLoading(false);
  };

  return (
    <StyledSectionWrapper css={removePaddBottom}>
      <StyledContainerWrapper>
        <StyledPreventAbuseWrapper>
          <div>
            <StyledSubTitle1 className="pr-100" paddingBottom="pb48">
              Prevent malicious requests and abuse
            </StyledSubTitle1>
            <StyledToggleWrapper>
              <StyledFooterTitle
                className={"tabList" + (currentTab === "depth-limiting" ? " tabListActive" : "")}
                role="presentation"
                onClick={() => setCurrentTab("depth-limiting")}
                css={textUppercase}
              >
                depth limiting
              </StyledFooterTitle>
              <StyledFooterTitle
                className={"tabList" + (currentTab === "node-limiting" ? " tabListActive" : "")}
                role="presentation"
                onClick={() => setCurrentTab("node-limiting")}
                css={textUppercase}
              >
                node limiting
              </StyledFooterTitle>
              <StyledFooterTitle
                className={"tabList" + (currentTab === "rate-limiting" ? " tabListActive" : "")}
                role="presentation"
                onClick={() => setCurrentTab("rate-limiting")}
                css={textUppercase}
              >
                Rate limiting
              </StyledFooterTitle>
            </StyledToggleWrapper>
          </div>
          <div>
            <StyledDesc1>
              API performance issues are typically caused by malicious or poorly implemented
              queries. Hasura Cloud lets you configure limits and restrict operations to prevent
              abuse.
              <br />
              <br />
              <div css={currentTab === "rate-limiting" ? showTab : hideTab}>
                <ul>
                  <li>
                    <img className="leftIcon" src={tickCircle} alt="Tick" />
                    Restrict the number of GraphQL operations per minute.
                  </li>
                  <li>
                    <img className="leftIcon" src={tickCircle} alt="Tick" />
                    Configure it per IP / per role / per user
                  </li>
                </ul>
              </div>
              <div css={currentTab === "depth-limiting" ? showTab : hideTab}>
                <ul>
                  <li>
                    <img className="leftIcon" src={tickCircle} alt="Tick" />
                    Restrict the operation based on the depth of GraphQL query.
                  </li>
                </ul>
              </div>
              <div css={currentTab === "node-limiting" ? showTab : hideTab}>
                <ul>
                  <li>
                    <img className="leftIcon" src={tickCircle} alt="Tick" />
                    Set a limit on the total number of GraphQL nodes to prevent unintentional usage
                    and inflated database billing.
                  </li>
                </ul>
              </div>
            </StyledDesc1>
          </div>
        </StyledPreventAbuseWrapper>
        <StyledPreventAbuseImg>
          <div css={currentTab === "rate-limiting" ? showTab : hideTab}>
            <StyledCodeBlockWrapperTwoColumn>
              <div className="codeBlockList">
                <div css={alignSelfStart}>
                  <div className="codeDesc">
                    GraphQL Query
                    <br />
                    <span>Try making more than 10 requests/min</span>
                  </div>
                  <div className="codeBlockContainer">
                    <CopyBlock
                      language="graphql"
                      text={queryDemoWithCaching}
                      showLineNumbers={false}
                      theme={dracula}
                      wrapLines={true}
                      codeBlock
                    />
                  </div>
                </div>
                <div className="codeBlockFooter" css={alignSelfEnd}>
                  <div className="buttonWrapper">
                    <StyledButton
                      variant="lightGray"
                      size="md"
                      btnWidth="width100"
                      onClick={() => {
                        getMeasuredQueryResponseRateLimiting();
                      }}
                      disabled={rateLimitingLoading}
                    >
                      {rateLimitingLoading ? "Fetching..." : "Try it Out"}
                    </StyledButton>
                  </div>
                </div>
              </div>
              <div className="codeBlockList">
                <div>
                  <div className="codeDesc">Response</div>
                  <div
                    className={`codeBlockContainer ${
                      !rateLimiting.data || rateLimitingLoading ? "blurDesc" : ""
                    }`}
                  >
                    <CopyBlock
                      language="javascript"
                      text={rateLimiting.data || defaultResponseRateLimiting}
                      showLineNumbers={false}
                      theme={dracula}
                      wrapLines={true}
                      codeBlock
                      customStyle={{
                        overflowY: `${
                          !rateLimiting.data || rateLimitingLoading ? "hidden" : "scroll"
                        }`,
                      }}
                    />
                  </div>
                  {rateLimitingLoading ? (
                    <div className="loader">
                      <Lottie options={defaultOptions} width={150} />
                    </div>
                  ) : null}
                </div>
              </div>
            </StyledCodeBlockWrapperTwoColumn>
          </div>
          <div css={currentTab === "depth-limiting" ? showTab : hideTab}>
            <StyledCodeBlockWrapperTwoColumn>
              <div className="codeBlockList">
                <div css={alignSelfStart}>
                  <div className="codeDesc">GraphQL Query</div>
                  <div className="codeBlockContainer">
                    <CopyBlock
                      language="graphql"
                      text={depthLimitingQuery}
                      showLineNumbers={false}
                      theme={dracula}
                      wrapLines={true}
                      codeBlock
                    />
                  </div>
                </div>
                <div className="codeBlockFooter" css={alignSelfEnd}>
                  <div className="buttonWrapper">
                    <StyledButton
                      variant="lightGray"
                      size="md"
                      btnWidth="width100"
                      onClick={() => {
                        getMeasuredQueryResponseDepthLimiting();
                      }}
                      disabled={depthLimitingLoading}
                    >
                      {depthLimitingLoading ? "Fetching..." : "Try it Out"}
                    </StyledButton>
                  </div>
                </div>
              </div>
              <div className="codeBlockList">
                <div>
                  <div className="codeDesc">Response</div>
                  <div
                    className={`codeBlockContainer ${
                      !depthLimiting?.data || depthLimitingLoading ? "blurDesc" : ""
                    }`}
                  >
                    <CopyBlock
                      language="javascript"
                      text={depthLimiting?.data || defaultResponseDepthLimiting}
                      showLineNumbers={false}
                      theme={dracula}
                      wrapLines={true}
                      codeBlock
                      customStyle={{
                        overflowY: `${
                          !depthLimiting.data || depthLimitingLoading ? "hidden" : "scroll"
                        }`,
                      }}
                    />
                  </div>
                  {depthLimitingLoading ? (
                    <div className="loader">
                      <Lottie options={defaultOptions} width={150} />
                    </div>
                  ) : null}
                </div>
              </div>
            </StyledCodeBlockWrapperTwoColumn>
          </div>
          <div css={currentTab === "node-limiting" ? showTab : hideTab}>
            <StyledCodeBlockWrapperTwoColumn>
              <div className="codeBlockList">
                <div css={alignSelfStart}>
                  <div className="codeDesc">GraphQL Query</div>
                  <div className="codeBlockContainer">
                    <CopyBlock
                      language="graphql"
                      text={nodeLimitingQuery}
                      showLineNumbers={false}
                      theme={dracula}
                      wrapLines={true}
                      codeBlock
                    />
                  </div>
                </div>
                <div className="codeBlockFooter" css={alignSelfEnd}>
                  <div className="buttonWrapper">
                    <StyledButton
                      variant="lightGray"
                      size="md"
                      btnWidth="width100"
                      onClick={() => {
                        getMeasuredQueryResponseNodeLimiting();
                      }}
                      disabled={nodeLimitingLoading}
                    >
                      {nodeLimitingLoading ? "Fetching..." : "Try it Out"}
                    </StyledButton>
                  </div>
                </div>
              </div>
              <div className="codeBlockList">
                <div>
                  <div className="codeDesc">Response</div>
                  <div
                    className={`codeBlockContainer ${
                      !nodeLimiting.data || nodeLimitingLoading ? "blurDesc" : ""
                    }`}
                  >
                    <CopyBlock
                      language="javascript"
                      text={nodeLimiting.data || defaultResponseNodeLimiting}
                      showLineNumbers={false}
                      theme={dracula}
                      wrapLines={true}
                      codeBlock
                      customStyle={{
                        overflowY: `${
                          !nodeLimiting.data || nodeLimitingLoading ? "hidden" : "scroll"
                        }`,
                      }}
                    />
                  </div>
                  {nodeLimitingLoading ? (
                    <div className="loader">
                      <Lottie options={defaultOptions} width={150} />
                    </div>
                  ) : null}
                </div>
              </div>
            </StyledCodeBlockWrapperTwoColumn>
          </div>
        </StyledPreventAbuseImg>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default PreventAbuse;
