import React from "react";
import Helmet from "react-helmet";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import SecurityIndex from "../../components/solutions/security/SecurityIndex";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/security-graphql.png",
};

const canonicalUrl = "https://hasura.io/graphql/security/";

const faqSchema = [
  {
    "@type": "Question",
    name: "How is security different in GraphQL?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "GraphQL has a single endpoint and a dynamic request body. Traditional methods of limiting URL access won’t work with GraphQL.",
    },
  },
  {
    "@type": "Question",
    name: "Does the introspection feature make GraphQL vulnerable?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "GraphQL Introspection exposes the underlying schema that can be queried against the endpoint, thus being vulnerable to attacks. It is very useful during development but is recommended to disable in production.",
    },
  },
  {
    "@type": "Question",
    name: "What kind of security can be added based on GraphQL nodes?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "Authorization rules can be added for each node and limiting which nodes can be accessed can be configured too.",
    },
  },
  {
    "@type": "Question",
    name: "Can we only allow a selected list of queries to be made against a GraphQL API?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "Hasura supports Allow Lists that lets you configure a list of queries that needs to be available for your production app. Every other request will be rejected.",
    },
  },
  {
    "@type": "Question",
    name: "How do you perform authentication with GraphQL?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "Authentication with GraphQL typically depends on the provider being used. For example, if you are using a JWT solution with Hasura, you can pass the access token in the header.",
    },
  },
];

const Security = ({ location }) => (
  <Layout location={location}>
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "FAQPage",
          mainEntity: faqSchema,
        })}
      </script>
    </Helmet>
    <Seo
      title="Secure your GraphQL API with Hasura Cloud | Hasura"
      description="GraphQL security for an existing API is declarative in Hasura Cloud. Follow best practices to configure authz rules, allow lists, rate limiting & schema restrictions"
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <SecurityIndex />
  </Layout>
);

export default Security;
