import React, { Fragment, useRef, useMemo } from "react";

import SubNav from "../common/SubNav";
import TopBanner from "../common/TopBanner";
import PreventAbuse from "./PreventAbuse";
import CachingWithHasura from "../common/CachingWithHasura";
import Features from "../../database/sqlServer/Features";
import Resources from "../common/Resources";
import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import GetStarted from "../common/GetStarted";
import Faq from "../common/Faq";

import { StyledDesc1 } from "../../shared/StyledTypography";

import dataExposure from "../images/data-exposure.png";
import allowList from "../images/allow-list.svg";
import auth from "../images/auth.svg";
import visible from "../images/visible-icon.svg";
import allowListPanel from "../images/allow-list-panel.png";
import serviceLevelSecurity from "../images/service-level-security.png";
import authorization from "../images/authorization.png";

const topBannerState = {
  title: "Secure your GraphQL APIs in a few clicks",
  subTitle: "Declaratively protect your GraphQL APIs with Hasura Cloud",
  btnContent: "Get Started with Security",
  btnLink: "https://cloud.hasura.io/signup?pg=security&plcmt=body&cta=get-started-with-security&tech=default",
  list: ["Secure by default", "Fully configurable API limits and Allow Lists", "Flexible and powerful Authorization Rules "],
  linkView: {
    title: "View Recording",
    linkText: "Securing your API with Hasura",
    linkUrl: "/events/webinar/securing-your-api-with-hasura/",
  }
}

const resourceListState =[
  {
    title: "Hasura Cloud Security",
    desc: "Advanced Tutorial covering various configurations to secure data access.",
    linkUrl: "https://hasura.io/learn/graphql/hasura-advanced/security/",
  },
  {
    title: "Hasura Authentication explained",
    desc: "Learn more about the different types of authentication methods that work with Hasura.",
    linkUrl: "https://hasura.io/blog/hasura-authentication-explained/",
  },
  {
    title: "GraphQL API Security",
    desc: "How to safe-guard your app against security threats using Hasura Cloud.",
    linkUrl: "https://hasura.io/blog/graphql-api-security-with-hasura-cloud/",
  },
]

const faqAllState = [
  {
    id: "how-is-security-different-in-graphql",
    question: "How is security different in GraphQL?",
    answer: <StyledDesc1>
      GraphQL has a single endpoint and a dynamic request body. Traditional methods of limiting URL access won’t work with GraphQL.
    </StyledDesc1>,
  },
  {
    id: "does-the-introspection-feature-make-graphql-vulnerable",
    question: "Does the introspection feature make GraphQL vulnerable?",
    answer: <StyledDesc1>
      GraphQL Introspection exposes the underlying schema that can be queried against the endpoint, thus being vulnerable to attacks. It is very useful during development but is recommended to disable in production.
    </StyledDesc1>,
  },
  {
    id: "what-kind-of-security-can-be-added-based-on-graphql-nodes",
    question: "What kind of security can be added based on GraphQL nodes?",
    answer: <StyledDesc1>
      Authorization rules can be added for each node and limiting which nodes can be accessed can be configured too.
    </StyledDesc1>,
  },
  {
    id: "can-we-only-allow-a-selected-list-of-queries-to-be-made-against-a-graphql-api",
    question: "Can we only allow a selected list of queries to be made against a GraphQL API?",
    answer: <StyledDesc1>
      Hasura supports Allow Lists that lets you configure a list of queries that needs to be available for your production app. Every other request will be rejected.
    </StyledDesc1>,
  },
  {
    id: "how-do-you-perform-authentication-with-graphql",
    question: "How do you perform authentication with GraphQL?",
    answer: <StyledDesc1>
      Authentication with GraphQL typically depends on the provider being used. For example, if you are using a JWT solution with Hasura, you can pass the access token in the header.
    </StyledDesc1>,
  },
]


const SecurityIndex = () => {
  const preventAbuseRef = useRef(null);
  const preventScrapingRef = useRef(null);
  const resourcesRef = useRef(null);
  const allowListRef = useRef(null);
  const serviceLevelSecurityRef = useRef(null);
  const authorizationRef = useRef(null);

  const sectionRefs = useMemo(
    () => [
      { name: "Prevent Abuse", section: "prevent-abuse", ref: preventAbuseRef },
      { name: "Prevent Scraping", section: "prevent-scraping", ref: preventScrapingRef },
      { name: "Resources", section: "resources", ref: resourcesRef },
    ],
    []
  );

  const securitySection = {
    "allow-list": { section: "allow-list", ref: allowListRef },
    "service-level-security": { section: "service-level-security", ref: serviceLevelSecurityRef },
    "authorization": { section: "authorization", ref: authorizationRef },
  }

  const securityListState = [
    {
      id: "allow-list",
      imgSrc: allowList,
      linkContent: "Allow Lists",
      title: "Allow Lists",
      desc: <span>
        <ul className="discUl">
          <li className="disc">Allow lists can be configured to safely allow a limited number of GraphQL operations (queries/mutations/subscriptions) for your project.</li>
          <li className="disc">Review and approve operations.</li>
          <li className="disc">Explicitly add Introspection queries if required.</li>
        </ul>
      </span>,
      panelImg: allowListPanel,
    },
    {
      id: "service-level-security",
      imgSrc: auth,
      linkContent: "Service Level Security",
      title: "Service Level Security",
      desc: <span>
        Hasura uses PostgreSQL prepared statements where parsing is skipped and only planning and execution takes place.<br/><br/>
        <ul className="discUl">
          <li className="disc">Manage team collaboration with varying levels of access.</li>
          <li className="disc">Set CORS policies to accept only a select set of domains to query from the client side.</li>
          <li className="disc">Protected with an admin secret by default All Hasura Cloud projects come pre-configured with an admin secret by default.</li>
        </ul>
      </span>,
      panelImg: serviceLevelSecurity,
    },
    {
      id: "authorization",
      imgSrc: visible,
      linkContent: "Authorization",
      title: "Authorization",
      desc: <span>
        <ul className="discUl">
          <li className="disc">Configure role based permissions</li>
          <li className="disc">Access control rules for CRUD</li>
          <li className="disc">Secure your remote GraphQL API declaratively</li>
        </ul>
      </span>,
      panelImg: authorization,
    },
  ]

  return (
    <>
      <SubNav
        sectionRefs={sectionRefs}
        pageName="Security"
        pagePath="/graphql/security/"
        currentDropDownMenu="solutions"
        subNavBtnLink="https://cloud.hasura.io/signup?pg=security&plcmt=sub-header&cta=get-started-now&tech=default"
      />
      <TopBanner topBannerState={topBannerState} paddBottom borderBottom/>
      <div id="prevent-abuse" ref={preventAbuseRef}>
        <PreventAbuse />
      </div>
      <div id="prevent-scraping" ref={preventScrapingRef}>
        <CachingWithHasura
          title="Prevent API Scraping and Excessive Data Exposure"
          subTitle="Turn off Schema Introspection in Production"
          desc=<span>GraphQL’s schema and type system and its introspection capabilities is the backbone behind all of the tooling around it. But for apps running in production, you do not want to inadvertently expose the schema or allow scraping of your APIs.<br/><br/>
            Disable schema introspection globally for your API using Hasura Console or declaratively using Metadata
          </span>
          imgsrc={dataExposure}
          pagePaddRemove
          leftPaddRemove
        />
        <Features
          title="Features"
          sections = {securitySection}
          tabListState = {securityListState}
        />
        {/* <ParametersSecurity /> */}
      </div>
      <div id="resources" ref={resourcesRef}>
        <Resources
          title="Resources"
          resourceList = {resourceListState}
          centerAlign
        />
        <StyledSectionWrapper>
          <GetStarted
            title="Improve your data security"
            btnContent="Start with Hasura Cloud"
            btnLink="https://cloud.hasura.io/signup?pg=security&plcmt=body&cta=start-with-hasura-cloud&tech=default"
          />
        </StyledSectionWrapper>
      </div>
      <Faq
        title="Frequently Asked Questions"
        faqAllState={ faqAllState }
      />
    </>
  );
};

export default SecurityIndex;
