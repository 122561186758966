import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

export const StyledCodeBlockWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  padding-top: 46px;
  .codeBlockList {
    background-color: ${COLORS.grey_96};
    border-radius: 8px;
    display: grid;
    position: relative;
    .loader {
      position: absolute;
      left: 50%;
      top: calc(50% - 20px);
      transform: translate(-50%, -50%);
    }
    .codeDesc {
      ${TYPOGRAPHY.description_2_mono}
      color: ${COLORS.blue_30};
      padding: 32px;
      padding-bottom: 24px;
      span {
        font-size: 14px;
      }
    }
    .maxHeightCodeBlock {
      max-height: 285px;
      overflow-y: scroll;
    }
    .codeBlockContainer {
      div {
        background: transparent;
        border-radius: 8px;
        padding: 32px !important;
        padding-top: 0 !important;
        min-height: 344px;
        max-height: 370px;
        button {
          display: none;
        }
        span {
          background: transparent !important;
        }
        code {
          flex: 1;
          background-color: transparent;
          color: inherit;
          white-space: pre-wrap;
          ${TYPOGRAPHY.description_2_mono};
          padding: 0 0 !important;
        }
      }
    }
    .sc-gsDJrp {
      background: transparent;
      border-radius: 8px;
      padding: 32px !important;
      padding-top: 0 !important;
      min-height: 344px;
      max-height: 370px;
      .sc-bdvvaa {
        display: none;
      }
      span {
        background: transparent !important;
      }
      code {
        flex: 1;
        background-color: transparent;
        color: inherit;
        white-space: pre-wrap;
        ${TYPOGRAPHY.description_2_mono};
        padding: 0 0 !important;
      }
    }
    .blurDesc {
      filter: blur(7px);
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
    }
    .codeBlockFooter {
      background-color: ${COLORS.grey_100};
      padding: 16px 32px;
      border-radius: 0 0 8px 8px;
      .buttonWrapper {
        padding: 8px 0;
      }
      .displayFlex {
        display: flex;
        .dottedLine {
          border-bottom: 1px dotted ${COLORS.grey_30};
          flex: 1;
          margin: 0 10px;
          margin-bottom: 6px;
        }
      }
    }
  }
  ${mq.between("md", "lg")} {
    grid-gap: 16px;
    .codeBlockList {
      .sc-gsDJrp {
        max-height: 335px;
      }
      .codeDesc {
        padding: 16px;
      }
      .codeBlockFooter {
        padding: 16px;
      }
    }
  }
  ${mq.below.md} {
    grid-gap: 16px;
    grid-template-columns: 1fr;
    .codeBlockList {
      /* max-width: 80% !important; */
      .sc-gsDJrp {
        max-height: none;
      }
      .codeDesc {
        padding: 16px;
      }
      .codeBlockFooter {
        padding: 16px;
      }
    }
  }

  @media (max-width: 600px) {
    overflow: auto;

    .codeBlockList {
      /* max-width: 80% !important; */
    }
  }
`;

export default StyledCodeBlockWrapper;
