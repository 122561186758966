import styled from "styled-components";
import { COLORS, ELEVATION } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

export const StyledPricingToggleWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 12px;
  padding: 4px;
  margin-bottom: 20px;
  position: relative;
  height: 44px;
  /* z-index: 1; */
  box-shadow: 0px 1px 3px rgba(28, 38, 63, 0.1), 0px 1px 2px rgba(28, 38, 63, 0.06);

  .toggleNavItemActiveBg {
    border-radius: 8px;
    background-color: #1699e2;
    /* width: calc(50% - 12px); */
    width: 124px;
    position: absolute;
    height: 36px;
    transition: all 0.2s ease-in-out;
    left: 4px;
  }

  .toggleTabRight {
    transform: translateX(100%);
    transition: all 0.2s ease-in-out;
  }

  .tabList {
    border-radius: 8px;
    cursor: pointer;
  }

  a {
    z-index: 1;
  }

  .tabListActive {
    box-shadow: ${ELEVATION.l_0};
    background-color: ${COLORS.sky_70};
    color: ${COLORS.sky_0};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.33);
    a {
      z-index: 1;
      .toggleNavItemActive {
        color: ${COLORS.sky_0};
      }
    }
  }
  ${mq.below.md} {
    width: 100%;
    &.mMw450 {
      max-width: 450px;
    }
    .toggleNavItemActiveBg {
      width: calc(50% - 4px);
    }
  }
`;

export const StyledToggleWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  background-color: ${COLORS.grey_6};
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 24px;
  position: relative;
  height: 64px;
  z-index: 1;
  .toggleNavItemActiveBg {
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.33);
    background-color: ${COLORS.blue_100};
    width: calc(50% - 12px);
    position: absolute;
    height: 40px;
    transition: all 0.2s ease-in-out;
    left: 12px;
  }
  .toggleTabRight {
    transform: translateX(100%);
    transition: all 0.2s ease-in-out;
  }
  .tabList {
    border-radius: 4px;
    cursor: pointer;
  }
  a {
    z-index: 1;
  }
  .tabListActive {
    box-shadow: ${ELEVATION.l_0};
    background-color: ${COLORS.sky_70};
    color: ${COLORS.sky_0};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.33);
    a {
      z-index: 1;
      .toggleNavItemActive {
        color: ${COLORS.sky_0};
      }
    }
  }
  ${mq.below.md} {
    width: 100%;
  }
`;

export default StyledToggleWrapper;
